import React, { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { bool, func, node, string } from 'prop-types';
import { UserType } from '../types';

const useStyles = makeStyles({
  input: {
    border: 'none',
    background: 'none',
    fontSize: '16px',
    padding: 0,
    outline: 'none',
    width: '100%',
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const ConversationDrawer = ({
  show,
  title,
  close,
  subject,
  id,
  children,
  moderatorId,
  client,
}) => {
  const idRef = useRef();
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const copyConversationId = () => {
    idRef.current.select();
    document.execCommand('copy');
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <Drawer open={show} anchor="right" onClose={close}>
      <Box px={2} width="60vw">
        <Box display="flex" direction="row" alignItems="center">
          <Box mr="auto">
            <Typography variant="h1">{title}</Typography>
          </Box>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="body1" color="textSecondary">
            Onderwerp
          </Typography>
          <Typography variant="subtitle1">{subject}</Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1" color="textSecondary">
                Gespreksleider:
              </Typography>
              <Typography variant="body1">
                {moderatorId ? moderatorId.name : '-'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" color="textSecondary">
                Gebruikersnaam:
              </Typography>
              <Typography variant="body1">
                {client ? client.name : ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" color="textSecondary">
                Gespreksidentificatienummer:
              </Typography>
              <Box display="flex" direction="row" alignItems="center">
                <input
                  type="text"
                  value={id}
                  ref={idRef}
                  className={classes.input}
                  readOnly
                />
                <Tooltip title="Nummer gekopieerd" open={showTooltip}>
                  <IconButton onClick={copyConversationId} size="small">
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {children && (
          <>
            <Divider />
            <Box my={2}>{children}</Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

ConversationDrawer.propTypes = {
  show: bool,
  title: string,
  close: func,
  subject: string,
  id: string,
  children: node,
  moderatorId: UserType,
  client: UserType,
};
export default ConversationDrawer;
