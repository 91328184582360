import { sortByDate } from '../index';
import { CONVERSATION_STATUS } from '../../constants';

const getClosingDate = logs => {
  const closingLog = logs
    .sort(sortByDate.descending('createdAt'))
    .find(({ status }) => status === CONVERSATION_STATUS.CLOSED);
  return closingLog ? closingLog.createdAt : null;
};

export default getClosingDate;
