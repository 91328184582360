const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_APPSYNC_API_KEY,
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_AWS_USER_POOLS_ID,
  REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  REACT_APP_OATH_DOMAIN,
  REACT_APP_OAUTH_REDIRECT,
} = process.env;

const awsConfig = {
  aws_project_region: REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: REACT_APP_AWS_APPSYNC_API_KEY,
  aws_cognito_identity_pool_id: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: REACT_APP_AWS_REGION,
  aws_user_pools_id: REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: REACT_APP_OATH_DOMAIN,
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: REACT_APP_OAUTH_REDIRECT,
    redirectSignOut: REACT_APP_OAUTH_REDIRECT,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_content_delivery_bucket: 'eurochat-dev-v1-dev',
  aws_content_delivery_bucket_region: REACT_APP_AWS_REGION,
  aws_content_delivery_url: 'https://d39610hnwc6gqd.cloudfront.net',
};

export default awsConfig;
