import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Send from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { func } from 'prop-types';

const useStyles = makeStyles({
  root: {
    padding: '0.75rem 0 0.75rem 1.5rem',
    '&:before': {
      border: 'none !important',
    },
    '&:after': {
      border: 'none !important',
    },
  },
});

const TextInput = ({ sendMessage }) => {
  const [messageContent, setMessageContent] = useState('');
  const [busy, setBusy] = useState(false);
  const classes = useStyles();

  const handleSendMessage = () => {
    setBusy(true);
    sendMessage(messageContent);
    setBusy(false);
    setMessageContent('');
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box m={2} mr={4} borderRadius={4} bgcolor="common.white">
      <form onKeyDown={handleKeyDown}>
        <Input
          fullWidth
          className={classes.root}
          placeholder="Typ hier je bericht..."
          multiline
          rowsMax={5}
          onChange={e => setMessageContent(e.target.value)}
          value={messageContent}
          variant="outlined"
          endAdornment={
            messageContent && (
              <InputAdornment position="end">
                {!busy ? (
                  <IconButton onClick={handleSendMessage}>
                    <Send />
                  </IconButton>
                ) : (
                  <Box p="0.75rem">
                    <CircularProgress size={24} />
                  </Box>
                )}
              </InputAdornment>
            )
          }
        />
      </form>
    </Box>
  );
};

TextInput.propTypes = {
  sendMessage: func,
};

export default TextInput;
