const setEmptyProperties = (data, prop) =>
  data[prop] ? data[prop] : { items: [] };

const parseConversation = conversation => ({
  ...conversation,
  messages: setEmptyProperties(conversation, 'messages'),
  notes: setEmptyProperties(conversation, 'notes'),
  conversationStatusLog: setEmptyProperties(
    conversation,
    'conversationStatusLog'
  ),
});

export default parseConversation;
