export const CONVERSATION_STATUS = {
  OPEN: 'open',
  ACTIVE: 'active',
  CLOSED: 'closed',
  ARCHIVED: 'archived',
};

export const CONVERSATION_CLOSING_TYPE = {
  NO_CONSULT: 'NO_CONSULT',
  CONSULT_AFTERWARDS: 'CONSULT_AFTERWARDS',
  CONSULT_CONTACT: 'CONSULT_CONTACT',
  CONSULT_TAKE_OVER: 'CONSULT_TAKE_OVER',
};

export const LOG_TYPES = {
  ...CONVERSATION_STATUS,
  NOTE: 'note',
  CONSULT: 'consult',
  CONSULT_NOTE: 'consultWithNote',
  CLOSE_TYPE_UPDATE: 'closeTypeUpdate',
};

export const CONVERSATION_LIMIT = 50;
