import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { bool } from 'prop-types';
import FromToForm from '../../containers/FromToForm';
import { startOfMonth } from 'date-fns';
import { API, graphqlOperation } from 'aws-amplify';
import { generateReport } from '../../graphql/queries';

const Statistics = ({ isVisible = true }) => {
  const defaultStart = startOfMonth(new Date());
  const defaultEnd = new Date();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(params => {
    setLoading(true);
    return API.graphql(graphqlOperation(generateReport, params))
      .then(({ data: { generateReport } }) => {
        const csvContent = 'data:text/csv;charset=utf-8,' + generateReport;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'statistieken.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box display={isVisible ? 'block' : 'none'}>
      <Typography variant="h1">GEARCHIVEERDE DOSSIERS</Typography>
      <Typography variant="body1">
        Genereer een CSV-bestand met alle gearchiveerde dossiers voor periode:
      </Typography>
      <FromToForm
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
        handleClick={handleClick}
        buttonText="Downloaden"
        busy={loading}
      />
    </Box>
  );
};

Statistics.propTypes = {
  isVisible: bool,
};

export default Statistics;
