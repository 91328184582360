import React, { useContext, useState } from 'react';
import ConversationHeaderComponent from '../components/ConversationHeader';
import stateContext from '../contexts/stateContext';
import ConfirmationPrompt from '../components/ConfirmationPrompt';
import CloseConversationPrompt from '../components/CloseConversationPrompt';
import InformationDrawer from './InformationDrawer';
import NotesDrawer from './NotesDrawer';
import { API, graphqlOperation } from 'aws-amplify';

import { updateConversation } from '../actions';
import { userContext } from '../contexts/userContext';

import { CONVERSATION_STATUS, LOG_TYPES } from '../constants';
import { updateCountEvent } from '../events';
import { updateConversationAndCreateLog } from '../graphql/customMutations';

const ConversationHeader = () => {
  const {
    state: { conversation },
    dispatch,
  } = useContext(stateContext);
  const [showPrompt, setShowPrompt] = useState('');
  const [showDrawer, setShowDrawer] = useState('');
  const { user } = useContext(userContext);

  const handleUpdateQuery = payload => {
    API.graphql(
      graphqlOperation(updateConversationAndCreateLog, {
        ...payload,
        logConversationUserName: user.name,
        conversationId: conversation.id,
      })
    ).then(({ data }) => {
      dispatch(updateConversation(data.updateConversation));
      window.dispatchEvent(updateCountEvent);
    });
  };

  return (
    <>
      <ConversationHeaderComponent
        {...conversation}
        closeConversation={() => setShowPrompt('close')}
        reopenConversation={() => setShowPrompt('reopen')}
        showInformation={() => setShowDrawer('information')}
        showNotes={() => setShowDrawer('notes')}
        userId={user.id}
      />
      <ConfirmationPrompt
        show={showPrompt === 'reopen'}
        close={() => setShowPrompt('')}
        header="Gesprek terugplaatsen"
        content="Als je dit gesprek terug plaatst word je ontkoppeld als gespreksleider en wordt het gesprek teruggeplaatst in lijst ‘open’."
        confirmLabel="terugplaatsen"
        declineLabel="annuleren"
        action={() => {
          handleUpdateQuery({
            status: CONVERSATION_STATUS.OPEN,
            logStatus: LOG_TYPES.OPEN,
          });
        }}
      />
      <CloseConversationPrompt
        show={showPrompt === 'close'}
        close={() => setShowPrompt('')}
        action={value => {
          handleUpdateQuery({
            closedType: value,
            status: CONVERSATION_STATUS.CLOSED,
            logStatus:
              conversation.status === CONVERSATION_STATUS.CLOSED
                ? LOG_TYPES.CLOSE_TYPE_UPDATE
                : LOG_TYPES.CLOSED,
          });
        }}
        conversation={conversation}
      />
      <InformationDrawer
        show={showDrawer === 'information'}
        close={() => setShowDrawer('')}
        conversation={conversation}
      />
      <NotesDrawer
        show={showDrawer === 'notes'}
        close={() => setShowDrawer('')}
      />
    </>
  );
};

export default ConversationHeader;
