import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { bool, func, instanceOf, string } from 'prop-types';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import { Button, CircularProgress } from '@material-ui/core';

const FromToDateForm = ({
  startValue,
  setStartValue,
  endValue,
  setEndValue,
  handleReset,
  handleClick,
  buttonText,
  busy,
}) => {
  return (
    <Box display="flex" mt={4}>
      <Box mr={1} display="flex" flexDirection="column" justifyContent="center">
        <Button onClick={handleReset} fullWidth>
          Opnieuw
        </Button>
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
        <Box width="20%" mr={1}>
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            label="Van (datum)"
            value={startValue}
            disableFuture
            fullWidth
            autoOk
            format="dd MMMM yyyy"
            onChange={newDate => setStartValue(newDate)}
          />
        </Box>
        <Box width="20%" mr={1}>
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            label="Tot en met (datum)"
            value={endValue}
            minDate={startValue}
            maxDate={new Date()}
            disableFuture
            fullWidth
            autoOk
            format="dd MMMM yyyy"
            onChange={newDate => setEndValue(newDate)}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="15%"
      >
        <Button
          onClick={handleClick}
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
          disabled={busy}
        >
          {buttonText}
          <Box display={busy ? 'flex' : 'none'} ml={1}>
            <CircularProgress size={20} />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

FromToDateForm.propTypes = {
  startValue: instanceOf(Date),
  endValue: instanceOf(Date),
  setStartValue: func,
  setEndValue: func,
  handleReset: func,
  handleClick: func,
  buttonText: string,
  busy: bool,
};

export default FromToDateForm;
