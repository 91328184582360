export default `
  fragment NoteFragment on Note {
    id
    conversationId
    content
    consultedSecondLine
    createdAt
    updatedAt
  }
`;
