import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { arrayOf, func, oneOf, shape, string } from 'prop-types';
import { CONVERSATION_STATUS } from '../constants';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { UserType } from '../types';

const ConversationTabs = ({
  selectedConversations,
  userId,
  onClose,
  onChange,
  value,
  newMessages,
}) => (
  <Box position="relative">
    <Box
      bgcolor="grey.300"
      position="absolute"
      bottom={0}
      width="100%"
      height="2px"
    />
    <Tabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      variant="scrollable"
    >
      {selectedConversations.map(({ id, client, status, moderatorId }) => (
        <Tab
          key={id}
          value={id}
          label={
            <Box
              display="flex"
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Typography variant="caption" noWrap>
                {client.name}{' '}
                {newMessages.includes(id) && (
                  <FiberManualRecordIcon
                    color="secondary"
                    fontSize="inherit"
                    style={{ verticalAlign: 'middle' }}
                  />
                )}
              </Typography>
              {status === CONVERSATION_STATUS.ACTIVE &&
              moderatorId.id === userId ? null : (
                <CloseIcon
                  onClick={e => onClose(e, id)}
                  fontSize="small"
                  style={{ marginLeft: '0.5rem' }}
                />
              )}
            </Box>
          }
        />
      ))}
    </Tabs>
  </Box>
);

ConversationTabs.propTypes = {
  selectedConversations: arrayOf(
    shape({
      id: string,
      client: UserType,
      status: oneOf(Object.values(CONVERSATION_STATUS)),
      moderatorId: UserType,
    })
  ),
  userId: string,
  onClose: func,
  onChange: func,
  value: string,
  newMessages: arrayOf(string),
};
export default ConversationTabs;
