import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import { func } from 'prop-types';

const useStyles = makeStyles({
  sortIcon: {
    transform: ({ sortByOldest }) => `rotate(${sortByOldest ? '180deg' : 0})`,
    transition: 'transform 0.3s linear',
  },
});

const SortConversations = ({ toggleSort }) => {
  const [sortByOldest, setSortByOldest] = useState(true);
  const classes = useStyles({ sortByOldest });

  const handleSorting = value => {
    toggleSort(value);
    setSortByOldest(value);
  };

  return (
    <Box p={2}>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        Sorteer gesprekken
      </Typography>
      <Box display="flex" alignItems="center">
        <Box mr="auto">
          <Typography>
            {sortByOldest ? 'oudste eerst' : 'nieuwste eerst'}
          </Typography>
        </Box>
        <IconButton
          onClick={() => handleSorting(!sortByOldest)}
          size="small"
          className={classes.sortIcon}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

SortConversations.propTypes = {
  toggleSort: func,
};

export default SortConversations;
