import React, { useState } from 'react';
import FromToFormComponent from '../components/FromToDateForm';
import { bool, func, instanceOf, string } from 'prop-types';
import { format } from 'date-fns';

const FromToForm = ({
  defaultStart,
  defaultEnd,
  handleClick,
  busy = false,
  buttonText,
}) => {
  const [startValue, setStartValue] = useState(defaultStart);
  const [endValue, setEndValue] = useState(defaultEnd);

  const handleClickWrapper = () => {
    handleClick({
      startDate: format(startValue, 'yyyy-MM-dd'),
      endDate: format(endValue, 'yyyy-MM-dd'),
    });
  };

  const handleReset = () => {
    setStartValue(defaultStart);
    setEndValue(defaultEnd);
  };

  return (
    <FromToFormComponent
      startValue={startValue}
      setStartValue={setStartValue}
      endValue={endValue}
      setEndValue={setEndValue}
      handleClick={handleClickWrapper}
      handleReset={handleReset}
      buttonText={buttonText}
      busy={busy}
    />
  );
};

FromToForm.propTypes = {
  defaultStart: instanceOf(Date),
  defaultEnd: instanceOf(Date),
  handleClick: func,
  busy: bool,
  buttonText: string,
};

export default FromToForm;
