import React, { useContext, useEffect, useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import NavList from '../../containers/NavList';
import NavListBottom from '../../containers/NavListBottom';
import User from '../../containers/User';
import ConversationList from '../../containers/ConversationList/index.js';
import ConversationTabs from '../../containers/ConversationTabs';
import ConversationHeader from '../../containers/ConversationHeader';
import ConversationFooter from '../../containers/ConversationFooter';
import Messages from '../../containers/Messages';
import Conversation from '../../containers/Conversation';
import { API, graphqlOperation } from 'aws-amplify';
import {
  addNewMessageNotification,
  addToConversationList,
  updateConversation,
} from '../../actions';
import stateContext from '../../contexts/stateContext';
import parseConversation from '../../utils/parseConversation';
import { onCreateConversationSubscription } from './onCreateConversationSubscription';
import Typography from '@material-ui/core/Typography';
import { version } from '../../../package.json';
import { getConversationById } from '../../graphql/queries';
import { onUpdateConversationSubscription } from './onUpdateConversationSubscription';
import showNotification from '../../utils/showNotification';
import { CONVERSATION_STATUS } from '../../constants';
import { userContext } from '../../contexts/userContext';
import { onCreateAllMessageSubscription } from './onCreateAllMessageSubscription';
import useSearchParams from '../../hooks/useSearchParams';
import { updateCountEvent } from '../../events';
import Settings from '../Settings';

const Dashboard = () => {
  const { user } = useContext(userContext);
  const { state, dispatch } = useContext(stateContext);
  const currentConversationId = useSearchParams('current');
  const [settingsState, setSettingsState] = useState(null);

  const toggleSettings = useCallback(() => {
    setSettingsState(settingsState === 'instellingen' ? null : 'instellingen');
  }, [settingsState]);

  useEffect(() => {
    const createConversationSubscription = API.graphql(
      graphqlOperation(onCreateConversationSubscription)
    ).subscribe({
      next: ({
        value: {
          data: { onCreateConversation },
        },
      }) => {
        const parsedConversation = parseConversation(onCreateConversation);
        dispatch(addToConversationList(parsedConversation));
        showNotification(`Nieuw gesprek van ${parsedConversation.client.name}`);
        window.dispatchEvent(updateCountEvent);
      },
    });

    const updateConversationSubscription = API.graphql(
      graphqlOperation(onUpdateConversationSubscription)
    ).subscribe({
      next: ({
        value: {
          data: {
            onUpdateConversation: { id },
          },
        },
      }) =>
        /**
         * The onUpdateConversation subscription fails on closed conversations.
         * That's why the query is called instead of using the response from
         * the subscription.
         */
        API.graphql(graphqlOperation(getConversationById, { id })).then(
          ({ data: { getConversationById } }) => {
            dispatch(updateConversation(getConversationById));
            window.dispatchEvent(updateCountEvent);
          }
        ),
    });

    const messageSubscription = API.graphql(
      graphqlOperation(onCreateAllMessageSubscription)
    ).subscribe({
      next: ({
        value: {
          data: {
            onCreateAllMessage: { conversationId },
          },
        },
      }) => {
        const conversation = state.conversationList.find(
          ({ id }) => id === conversationId
        );
        if (
          conversation &&
          conversationId !== currentConversationId &&
          (conversation.status === CONVERSATION_STATUS.OPEN ||
            conversation.moderatorId.id === user.id)
        ) {
          dispatch(addNewMessageNotification(conversationId));
          showNotification(`Nieuw bericht van ${conversation.client.name}`);
        }
      },
    });

    return () => {
      createConversationSubscription.unsubscribe();
      updateConversationSubscription.unsubscribe();
      messageSubscription.unsubscribe();
    };
  }, [currentConversationId, state.conversationList, user.id, dispatch]);
  return (
    <Grid container>
      <Grid item xs={2} style={{ zIndex: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          boxShadow={12}
          height="100vh"
        >
          <Box p={2} pr={6}>
            <img
              src="/images/eurocross_UC_logo.png"
              alt="Eurocross logo"
              width="100%"
            />
            <Typography
              variant="overline"
              color="textSecondary"
              display="block"
              align="center"
              style={{ lineHeight: 1 }}
            >
              {version}
            </Typography>
          </Box>
          <Divider />
          <Box mb="auto">
            <NavList />
          </Box>
          <Box>
            <NavListBottom
              onClick={toggleSettings}
              settingsState={settingsState}
            />
            <Divider />
            <User />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <ConversationList />
      </Grid>
      <Grid item xs={8}>
        <Box
          bgcolor="grey.100"
          height="100vh"
          display="flex"
          flexDirection="column"
        >
          <ConversationTabs />
          <Conversation>
            <ConversationHeader />
            <Messages />
            <ConversationFooter />
          </Conversation>
        </Box>
        <Settings
          settingsState={settingsState}
          setSettingsState={setSettingsState}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
