import React from 'react';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { format } from 'date-fns';
import StepContent from '@material-ui/core/StepContent';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { arrayOf, bool, shape, string } from 'prop-types';
import sortByDate from '../utils/sortByDate';
import { UserType } from '../types';

const Notes = ({ notes }) => (
  <>
    <Typography variant="subtitle1" gutterBottom>
      notitiegeschiedenis (laatste eerst)
    </Typography>
    <Stepper orientation="vertical" style={{ padding: 0 }}>
      {notes.sort(sortByDate.descending('createdAt')).map(note => (
        <Step active={true} key={note.id}>
          <StepLabel icon={<RadioButtonUncheckedIcon color="primary" />}>
            <Typography variant="subtitle2" color="textSecondary">
              {format(new Date(note.createdAt), 'dd MMM yyyy HH:mm')} door{' '}
              {note.moderator.name}
            </Typography>
          </StepLabel>
          <StepContent>
            <Box bgcolor="grey.100" p={2}>
              {note.consultedSecondLine && (
                <Box display="flex" alignItems="center" direction="row">
                  <ErrorOutlineIcon />
                  <Box ml={1}>
                    <Typography variant="subtitle1">
                      Achterwacht geraadpleegd
                    </Typography>
                  </Box>
                </Box>
              )}
              {note.content}
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  </>
);

Notes.propTypes = {
  notes: arrayOf(
    shape({
      createdAt: string,
      consultedSecondLine: bool,
      content: string,
      moderator: UserType,
    })
  ),
};

export default Notes;
