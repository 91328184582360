import { createAction } from './utils';

// Action Types
export const SET_CONVERSATION_LIST = 'SET_CONVERSATION_LIST';
export const SET_NEXT_TOKEN = 'SET_NEXT_TOKEN';
export const ADD_TO_CONVERSATION_LIST = 'ADD_TO_CONVERSATION_LIST';
export const UPDATE_CONVERSATION_LIST = 'UPDATE_CONVERSATION_LIST';
export const SET_CONVERSATION = 'SET_CONVERSATION';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const DESELECT_CONVERSATION = 'DESELECT_CONVERSATION';
export const RESET_NEW_CONVERSATION_COUNT = 'RESET_NEW_CONVERSATION_COUNT';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const ADD_MESSAGE_NOTIFICATION = 'ADD_MESSAGE_NOTIFICATION';

// Actions
export const setConversationList = createAction(SET_CONVERSATION_LIST);
export const setNextToken = createAction(SET_NEXT_TOKEN);
export const addToConversationList = createAction(ADD_TO_CONVERSATION_LIST);
export const updateConversationList = createAction(UPDATE_CONVERSATION_LIST);
export const setConversation = createAction(SET_CONVERSATION);
export const updateConversation = createAction(UPDATE_CONVERSATION);
export const deselectConversation = createAction(DESELECT_CONVERSATION);
export const resetNewConversationCount = createAction(
  RESET_NEW_CONVERSATION_COUNT
);
export const addNote = createAction(ADD_NOTE);
export const addMessage = createAction(ADD_MESSAGE);
export const updateMessageStatus = createAction(UPDATE_MESSAGE_STATUS);
export const clearState = createAction(CLEAR_STATE);
export const addNewMessageNotification = createAction(ADD_MESSAGE_NOTIFICATION);
