import React, { useRef } from 'react';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { arrayOf, func, shape, string } from 'prop-types';

const Menu = ({ menuItems }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const ref = useRef();

  const handleClick = action => {
    action();
    setShowMenu(false);
  };

  return (
    <>
      <IconButton onClick={() => setShowMenu(true)} ref={ref}>
        <MoreVertIcon />
      </IconButton>
      <MuiMenu
        anchorEl={ref.current}
        open={showMenu}
        onClose={() => setShowMenu(false)}
      >
        {menuItems.map(({ label, action }) => (
          <MenuItem key={label} onClick={() => handleClick(action)}>
            {label}
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
};

Menu.propTypes = {
  menuItems: arrayOf(
    shape({
      label: string,
      action: func,
    })
  ),
};

export default Menu;
