import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { func, string } from 'prop-types';

const ButtonFooter = ({ action, text, buttonLabel }) => (
  <Box
    bgcolor="grey.300"
    p={2}
    pr={4}
    display="flex"
    flexDirection="row"
    justifyContent={action && buttonLabel ? 'space-between' : 'space-around'}
    alignItems="center"
  >
    <Typography variant="body1">{text}</Typography>
    {action && buttonLabel && (
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={action}
        >
          {buttonLabel}
        </Button>
      </Box>
    )}
  </Box>
);

ButtonFooter.propTypes = {
  action: func,
  text: string,
  buttonLabel: string,
};

export default ButtonFooter;
