import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Search from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { arrayOf, func, shape } from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import TextField from '@material-ui/core/TextField';
import { subDays } from 'date-fns';

const SearchConversations = React.forwardRef(
  ({ searchConversations, resetSearchResults, moderators }, ref) => {
    const [searchQuery, setSearchQuery] = useState({
      queryString: '',
    });
    const [showParams, setShowParams] = useState(false);

    const handleChange = (key, value) =>
      setSearchQuery({
        ...searchQuery,
        [key]: value,
      });

    const handleSubmitQuery = () => {
      // The value __none__ is needed because MUI won't work with an empty string.

      if (searchQuery.moderatorId === '__none__') {
        delete searchQuery.moderatorId;
      }

      if (searchQuery.queryString !== '' || 'startDate' in searchQuery) {
        searchConversations({ searchQuery: searchQuery });
      }
    };

    const toggleParams = open => {
      if (open) {
        setShowParams(true);
        setSearchQuery({
          ...searchQuery,
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
        });
      } else {
        setShowParams(false);
        setSearchQuery({
          queryString: searchQuery.queryString,
        });
      }
    };

    const handleReset = () => {
      resetSearchResults();
      toggleParams(false);
      setSearchQuery({ queryString: '' });
    };

    return (
      <Box p={2} ref={ref}>
        <Typography variant="subtitle2" color="textSecondary">
          Zoek en filter gesprekken
        </Typography>
        <Box my={1} mb={0}>
          <OutlinedInput
            startAdornment={<Search style={{ marginRight: '10px' }} />}
            placeholder="Zoekterm"
            value={searchQuery.queryString}
            fullWidth
            onChange={e => handleChange('queryString', e.target.value)}
          />
        </Box>
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          onClick={() => toggleParams(!showParams)}
        >
          <Typography variant="body1" align="center">
            Uitgebreid zoeken
          </Typography>
          {showParams ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Box>
        {showParams && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
            <Box mb={2}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                label="Van (datum)"
                value={searchQuery.startDate}
                disableFuture
                fullWidth
                autoOk
                format="dd MMMM yyyy"
                onChange={newDate => handleChange('startDate', newDate)}
              />
            </Box>
            <Box mb={2}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                label="Tot (datum)"
                value={searchQuery.endDate}
                minDate={searchQuery.startDate}
                maxDate={new Date()}
                disableFuture
                fullWidth
                autoOk
                format="dd MMMM yyyy"
                onChange={newDate => handleChange('endDate', newDate)}
              />
            </Box>
            <TextField
              select
              onChange={e => handleChange('moderatorId', e.target.value)}
              fullWidth
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              value={searchQuery.moderatorId}
              label="Gespreksleider"
            >
              <option value="__none__">Alle gespreksleiders</option>
              {moderators.map(moderator => (
                <option key={moderator.id} value={moderator.id}>
                  {moderator.name}
                </option>
              ))}
            </TextField>
          </MuiPickersUtilsProvider>
        )}
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            startIcon={<Search />}
            onClick={handleSubmitQuery}
          >
            Zoeken
          </Button>
        </Box>
        <Box mt={1}>
          <Button onClick={handleReset} fullWidth>
            Reset
          </Button>
        </Box>
      </Box>
    );
  }
);

SearchConversations.propTypes = {
  searchConversations: func,
  resetSearchResults: func,
  moderators: arrayOf(shape({})),
};

SearchConversations.displayName = 'SearchConversations';

export default SearchConversations;
