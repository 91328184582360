import { differenceInMinutes } from 'date-fns';

// todo: Add events to check if conversation has been answered

/**
 * Checks if a conversation has been left unanswered for more then 25 minutes
 * @param {string} createdAt
 * @returns {boolean}
 */
const conversationIsOverdue = createdAt =>
  differenceInMinutes(new Date(), new Date(createdAt)) > 25;

export default conversationIsOverdue;
