import React from 'react';
import MuiChip from '@material-ui/core/Chip';
import { object, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    borderColor: theme.palette.primary.lighter,
  },
}));

const Chip = ({ label, icon }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <MuiChip
      label={<Typography variant="overline">{label}</Typography>}
      variant="outlined"
      color="primary"
      size="small"
      className={classes.root}
      icon={<Icon color="inherit" fontSize="small" />}
    />
  );
};

Chip.propTypes = {
  label: string,
  icon: object,
};

export default Chip;
