import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ChatIcon from '@material-ui/icons/Chat';
import Menu from '../components/Menu';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { arrayOf, func, oneOf, shape, string, bool } from 'prop-types';
import { CONVERSATION_CLOSING_TYPE, CONVERSATION_STATUS } from '../constants';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { getCopy, sortByDate } from '../utils';
import { ConversationStatusLogType, UserType } from '../types';

const ConversationHeader = ({
  id,
  subject,
  deleted,
  status,
  createdAt,
  closedType,
  closeConversation,
  reopenConversation,
  showInformation,
  showNotes,
  moderatorId,
  userId,
  conversationStatusLog,
}) => {
  console.log('DELETED?', false);
  const menuItems = [
    {
      label: 'Gespreksinformatie',
      action: showInformation,
    },
    {
      label: 'Notities',
      action: showNotes,
    },
  ];
  const [closingLog, setClosingLog] = useState(null);
  const chipLabel =
    status === CONVERSATION_STATUS.ACTIVE
      ? getCopy(moderatorId.id === userId ? 'active' : 'observe')
      : getCopy(status);

  useEffect(() => {
    setClosingLog(
      conversationStatusLog.items
        .sort(sortByDate.descending('createdAt'))
        .find(({ status }) => status === CONVERSATION_STATUS.CLOSED)
    );
  }, [conversationStatusLog.items]);

  return (
    <Box p={2} pb={0}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box mr={2}>
          <Typography variant="h1">{subject}</Typography>
        </Box>
        <Box mr="auto">
          <Chip
            size="small"
            label={<Typography variant="overline">{chipLabel}</Typography>}
            color={
              ['actief', 'open'].indexOf(chipLabel) !== -1
                ? 'secondary'
                : 'default'
            }
            icon={<ChatIcon fontSize="small" />}
          />
        </Box>
        <Box>
          <Menu menuItems={menuItems} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="flex-end">
        <Box mr="auto">
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body1" color="textSecondary">
                Gespreksleider: {moderatorId ? moderatorId.name : ''}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Binnengekomen op:{' '}
                {format(new Date(createdAt), 'dd-MM-yyyy HH:mm')}
              </Typography>
              {!!closingLog && !!closedType && (
                <>
                  <Typography variant="body1" color="textSecondary">
                    Gesloten op:{' '}
                    {format(new Date(closingLog.createdAt), 'dd-MM-yyyy HH:mm')}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" color="textSecondary">
                      {getCopy(`${closedType}.title`)}
                    </Typography>
                    <IconButton size="small" onClick={closeConversation}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              )}
              {deleted && (
                <Typography variant="body1" color="textSecondary">
                  LET OP: De gebruiker heeft deze conversatie verwijderd
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary">
                ID: {id}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {status === CONVERSATION_STATUS.ACTIVE && moderatorId.id === userId && (
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  onClick={closeConversation}
                  variant="outlined"
                  color="primary"
                  disableElevation
                >
                  Sluiten
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={reopenConversation}
                  variant="outlined"
                  color="primary"
                  disableElevation
                >
                  {getCopy('reopen')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
    </Box>
  );
};

ConversationHeader.propTypes = {
  id: string,
  subject: string,
  deleted: bool,
  status: oneOf(Object.values(CONVERSATION_STATUS)),
  createdAt: string,
  closedAt: string,
  closedType: oneOf(Object.keys(CONVERSATION_CLOSING_TYPE)),
  closeConversation: func,
  reopenConversation: func,
  showInformation: func,
  showNotes: func,
  moderatorId: UserType,
  userId: string,
  conversationStatusLog: shape({
    items: arrayOf(ConversationStatusLogType),
  }),
};

export default ConversationHeader;
