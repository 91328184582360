import React, { useContext, useEffect, useState } from 'react';
import NavListComponent from '../components/NavList';
import stateContext from '../contexts/stateContext';
import { useHistory, useLocation } from 'react-router';
import { resetNewConversationCount } from '../actions';
import MailIcon from '@material-ui/icons/Mail';
import ChatIcon from '@material-ui/icons/Chat';
import InboxIcon from '@material-ui/icons/Inbox';
import { API, graphqlOperation } from 'aws-amplify';
import getConversationCountByStatus from '../graphql/getConversationCountByStatus';

const routes = [
  {
    path: 'open',
    icon: MailIcon,
    label: 'open',
  },
  {
    path: 'active',
    icon: ChatIcon,
    label: 'actief',
  },
  {
    path: 'closed',
    icon: InboxIcon,
    label: 'gesloten',
  },
  {
    path: 'archived',
    icon: InboxIcon,
    label: 'gearchiveerd',
  },
];

const NavList = () => {
  const { state, dispatch } = useContext(stateContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [initCount, setInitCount] = useState(true);
  const [count, setCount] = useState({ open: 0, active: 0 });
  const [fiftyPlusChats, setFiftyPlusChats] = useState({
    open: false,
    active: false,
  });

  const handleClick = path => {
    history.push(`/${path}${search}`);
  };

  if (pathname === '/open' && state.newConversationCount > 0) {
    dispatch(resetNewConversationCount(null));
  }

  const getConversationCount = async status => {
    try {
      const response = await API.graphql(
        graphqlOperation(getConversationCountByStatus, { status })
      );

      const {
        data: {
          getConversationsByStatus: { items },
        },
      } = await response;
      setCount(prevState => ({
        ...prevState,
        [status]: items.length,
      }));
      setFiftyPlusChats(prevState => ({
        ...prevState,
        [status]: items.length > 49 ? true : false,
      }));
    } catch (err) {
      console.error(`Failed getting count for ${status}:`, err);
    }
  };

  const updateCount = event => {
    event.preventDefault();
    setInitCount(true);
  };

  useEffect(() => {
    if (initCount) {
      setInitCount(false);
      getConversationCount('open');
      getConversationCount('active');
    }

    window.addEventListener('updateCount', updateCount);
    return () => {
      window.removeEventListener('updateCount', updateCount);
    };
  }, [initCount]);

  useEffect(() => {
    console.log('STATE OF APP', state);
  }, [state]);

  return (
    <NavListComponent
      routes={routes}
      newConversationCount={state.newConversationCount}
      count={count}
      pathname={pathname}
      onClick={handleClick}
      fiftyPlusChats={fiftyPlusChats}
    />
  );
};

export default NavList;
