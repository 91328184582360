import gql from 'graphql-tag';
import { userFragment, noteFragment, statusLogFragment } from '../../fragments';

export const closedAndArchivedConversationsQuery = gql`
query GetConversationsByStatus(
    $status: String!
    $limit: Int
    $nextToken: String
  ) {
    getConversationsByStatus(
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        status
        closedType
        createdAt
        updatedAt
        client {
          ...UserFragment
        }
        notes {
          items {
            ...NoteFragment
          }
        }
        conversationStatusLog {
          items {
            ...StatusLogFragment
          }
        }
      }
      nextToken
    }
  }${userFragment}, ${noteFragment}, ${statusLogFragment}`;
