import React, { useContext, useState } from 'react';
import { userContext } from '../contexts/userContext';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import WindowsIcon from '../components/WindowsIcon';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Login = () => {
  const { user, setUser } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState('azure');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginWithAzure = async () =>
    Auth.federatedSignIn({ provider: 'eurocross-dev-azure-ad' });

  const loginWithEmail = e => {
    e.preventDefault();
    setLoading(true);
    Auth.signIn(email, password)
      .then(result => setUser(result))
      .catch(err => {
        console.error('Login failed:', err);
        setLoading(false);
      });
  };

  return user ? (
    <Redirect to="/" />
  ) : (
    <Box
      bgcolor="common.white"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <img
            src="/images/eurocross_UC_logo.png"
            alt="Eurocross logo"
            width={200}
          />
          <Box my={4}>
            <Typography variant="h1" align="center">
              Inloggen bij medidesk
            </Typography>
          </Box>
        </Box>
        {method === 'azure' ? (
          <Button
            variant="contained"
            color="primary"
            onClick={loginWithAzure}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={16} /> : null}
            disableElevation
          >
            <WindowsIcon />
            <Box ml={2}>aanmelden met je microsoft account</Box>
          </Button>
        ) : (
          <Box width="300px">
            <form noValidate autoComplete="off" onSubmit={loginWithEmail}>
              <Grid container direction="column">
                <Box mb={1}>
                  <TextField
                    fullWidth
                    autoFocus
                    label="Email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Box>
                <Box mt={2}>
                  <Button
                    fullWidth
                    disabled={loading || (!email && !password)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    startIcon={loading && <CircularProgress size={20} />}
                  >
                    aanmelden
                  </Button>
                </Box>
              </Grid>
            </form>
          </Box>
        )}
        <Box mt={2}>
          <Button
            disableElevation
            onClick={() => setMethod(method === 'azure' ? 'email' : 'azure')}
            size="small"
          >
            {method === 'azure'
              ? 'Aanmelden met email'
              : 'Aanmelden met Microsoft'}
          </Button>
        </Box>
      </Box>

      <Box bgcolor="grey.200" position="absolute" bottom={16} p={2}>
        Gegevens die via dit systeem toegankelijk zijn, zijn strikt
        vertrouwelijk. Stel geen gegevens ter beschikking zonder toestemming van
        de Security Officer van Eurocross.
      </Box>
    </Box>
  );
};

export default Login;
