import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import TimerIcon from '@material-ui/icons/Timer';
import {
  conversationIsOverdue,
  conversationHasBeenReopend,
  getCopy,
} from '../utils';
import { any, arrayOf, bool, oneOf, shape, string } from 'prop-types';
import useSearchParams from '../hooks/useSearchParams';
import Chip from '../components/Chip';
import ReplayIcon from '@material-ui/icons/Replay';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { CONVERSATION_STATUS, LOG_TYPES } from '../constants';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { UserType } from '../types';

const ConversationListItem = ({
  id,
  subject,
  client,
  status,
  createdAt,
  closedAt,
  conversationStatusLog,
  hasNewMessage,
}) => {
  const { path } = useRouteMatch();
  const currenConversationId = useSearchParams('current');
  const history = useHistory();
  const isOverdue =
    conversationIsOverdue(createdAt) &&
    conversationStatusLog &&
    !conversationStatusLog.items.some(item => item.status === LOG_TYPES.ACTIVE);
  const hasBeenreopened =
    conversationStatusLog &&
    conversationHasBeenReopend(conversationStatusLog.items);
  const hasNote =
    conversationStatusLog &&
    conversationStatusLog.items.some(
      ({ status }) => status === LOG_TYPES.NOTE || status === LOG_TYPES.CONSULT
    );

  return (
    <>
      <CardActionArea onClick={() => history.push(`${path}?current=${id}`)}>
        <Box
          p={2}
          bgcolor={currenConversationId === id ? 'primary.light' : 'initial'}
        >
          <Typography variant="subtitle1" noWrap>
            {subject}
            {hasNewMessage && (
              <Box position="absolute" right={16} top={16}>
                <FiberManualRecordIcon color="secondary" fontSize="inherit" />
              </Box>
            )}
          </Typography>

          <Typography variant="body1" color="textSecondary">
            {client.name}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
            {isOverdue && <TimerIcon color="error" fontSize="small" />}
            <Typography
              variant="subtitle2"
              color={isOverdue ? 'error' : 'initial'}
            >
              {format(
                new Date(
                  [
                    CONVERSATION_STATUS.CLOSED,
                    CONVERSATION_STATUS.ARCHIVED,
                  ].includes(status)
                    ? closedAt
                    : createdAt
                ),
                'dd MMM yyyy HH:mm'
              )}
            </Typography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            {hasNote && <Chip label="notitie" icon={NoteAddIcon} />}
            {hasBeenreopened && (
              <Chip label={getCopy('reopened')} icon={ReplayIcon} />
            )}
          </Box>
        </Box>
      </CardActionArea>
      <Divider />
    </>
  );
};

ConversationListItem.propTypes = {
  id: string,
  subject: string,
  client: UserType,
  status: oneOf(Object.values(CONVERSATION_STATUS)),
  createdAt: string,
  closedAt: string,
  conversationStatusLog: shape({
    items: arrayOf(any),
  }),
  hasNewMessage: bool,
};

export default ConversationListItem;
