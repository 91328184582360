import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogTitle } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { bool, func, string } from 'prop-types';

const ConfirmationPrompt = ({
  show,
  close,
  header,
  content,
  confirmLabel,
  declineLabel,
  action,
}) => {
  const handleClick = value => {
    if (value) {
      action();
    }
    close();
  };

  return (
    <Dialog open={show} onClose={close}>
      {header && (
        <DialogTitle disableTypography>
          <Typography variant="subtitle1">{header}</Typography>
        </DialogTitle>
      )}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleClick(false)}
          disableElevation
        >
          {declineLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClick(true)}
          disableElevation
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationPrompt.propTypes = {
  show: bool.isRequired,
  close: func,
  header: string,
  content: string,
  confirmLabel: string.isRequired,
  declineLabel: string.isRequired,
  action: func.isRequired,
};

ConfirmationPrompt.defaultProps = {
  show: false,
  confirmLabel: 'bevestigen',
  declineLabel: 'annuleren',
};
export default ConfirmationPrompt;
