import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { arrayOf, func, shape, string } from 'prop-types';

const SimpleTabs = ({ onChange, value, tabs }) => (
  <Box position="relative">
    <Box
      bgcolor="grey.300"
      position="absolute"
      bottom={0}
      width="100%"
      height="2px"
    />
    <Tabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      variant="scrollable"
    >
      {tabs.map(({ id, name }) => (
        <Tab
          key={id}
          value={id}
          label={
            <Box
              display="flex"
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Typography variant="caption" noWrap>
                {name}
              </Typography>
            </Box>
          }
        />
      ))}
    </Tabs>
  </Box>
);

SimpleTabs.propTypes = {
  tabs: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onChange: func,
  value: string,
};

export default SimpleTabs;
