import React, { useContext } from 'react';
import NavListComponent from '../components/NavList';
import stateContext from '../contexts/stateContext';
import SettingsIcon from '@material-ui/icons/Settings';
import { func, string } from 'prop-types';

const routes = [
  {
    path: 'instellingen',
    icon: SettingsIcon,
    label: 'instellingen',
  },
];

const NavListBottom = ({ onClick, settingsState }) => {
  const { state } = useContext(stateContext);

  return (
    <NavListComponent
      routes={routes}
      newConversationCount={state.newConversationCount}
      onClick={onClick}
      pathname={`/${settingsState}`}
    />
  );
};

NavListBottom.propTypes = {
  onClick: func,
  settingsState: string,
};

export default NavListBottom;
