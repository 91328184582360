import React, { useCallback, useContext, useEffect, useState } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { withState } from './contexts/stateContext';
import { withTheme } from './theme';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { userContext, withAuthentication } from './contexts/userContext';

const App = () => {
  if (Notification.permission === 'default') {
    Notification.requestPermission();
  }
  const [hasError, setHasError] = useState(false);
  const { user } = useContext(userContext);

  const handleError = useCallback(
    ({ error }) => {
      if (!hasError && error.provider.socketStatus === 0) {
        setHasError(true);
        window.alert(
          'Het lijkt erop dat de verbinding met de server is verbroken.\n' +
            'Openstaande notities kunnen nog opgeslagen worden, zodat je niets verliest. Je kunt vervolgens de pagina verversen om de verbinding met de server te herstellen.'
        );
      }
    },
    [hasError]
  );

  useEffect(() => {
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [handleError]);

  return user ? (
    <HashRouter>
      <Switch>
        <Route
          path={['/open', '/active', '/closed', '/archived']}
          component={Dashboard}
        />
        <Redirect to="/open" path={['*', '/']} />
      </Switch>
    </HashRouter>
  ) : (
    <Login />
  );
};

export default [withTheme, withState, withAuthentication].reduce(
  (acc, hoc) => hoc(acc),
  App
);
