/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const onCreateConversationStatusLog = /* GraphQL */ `
  subscription OnCreateConversationStatusLog {
    onCreateConversationStatusLog {
      conversationId
      status
      name
      createdAt
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      conversationId
      content
      consultedSecondLine
      moderator {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      conversationId
      content
      consultedSecondLine
      moderator {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      createdAt
      conversationId
      content
      sender
      senderUser {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      receiver
      image
    }
  }
`;
export const onCreateAllMessage = /* GraphQL */ `
  subscription OnCreateAllMessage {
    onCreateAllMessage {
      createdAt
      conversationId
      content
      sender
      senderUser {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      receiver
      image
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      createdAt
      conversationId
      content
      sender
      senderUser {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      receiver
      image
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
