const getConversationCountByStatus = `
  query GetConversationsByStatus(
  $status: String!
) {
  getConversationsByStatus(
    status: $status
) {
    items {
      id
    }
  }
}`;

export default getConversationCountByStatus;
