import React, { useContext, useEffect, useState } from 'react';
import { filterListByStatus, sortByDate } from '../../utils';
import { CONVERSATION_STATUS, CONVERSATION_LIMIT } from '../../constants';
import stateContext from '../../contexts/stateContext';
import { userContext } from '../../contexts/userContext';
import { func } from 'prop-types';
import { activeConversationsQuery } from './activeConversationsQuery';
import SortConversations from '../../components/SortConversations';
import ConversationListItem from '../../components/ConversationListItem';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';

const ActiveConversationList = ({ fetchConversations }) => {
  const [loading, setLoading] = useState(false);
  let [pageNumber, setPageNumber] = useState(0);
  const [pageTokens, setPageTokens] = useState({});
  const { state } = useContext(stateContext);
  const { user } = useContext(userContext);
  const [sortByOldest, setSortByOldest] = useState(true);
  const hasNewMessages = conversationId =>
    state.newMessageNotifications.includes(conversationId);

  const getPrevPage = () => {
    Promise.all([
      fetchConversations(
        activeConversationsQuery,
        CONVERSATION_STATUS.ACTIVE,
        CONVERSATION_LIMIT,
        pageTokens[pageNumber].prev
      ),
    ]);
    setPageNumber(--pageNumber);
    setLoading(true);
  };

  const getNextPage = () => {
    Promise.all([
      fetchConversations(
        activeConversationsQuery,
        CONVERSATION_STATUS.ACTIVE,
        CONVERSATION_LIMIT,
        pageTokens[pageNumber].next
      ),
    ]);
    setPageNumber(++pageNumber);
    setLoading(true);
  };

  useEffect(() => {
    fetchConversations(activeConversationsQuery, CONVERSATION_STATUS.ACTIVE);
  }, [fetchConversations]);

  useEffect(() => {
    setLoading(false);
    setPageTokens({
      ...pageTokens,
      [pageNumber]: {
        prev: pageTokens[pageNumber - 2]
          ? pageTokens[pageNumber - 2].next
          : null,
        next: state.nextToken,
      },
    });
  }, [state.conversationList]);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <SortConversations toggleSort={setSortByOldest} />
      <Box p={2} pb={0}>
        <Typography variant="subtitle2" color="textSecondary">
          Actieve gesprekken
        </Typography>
      </Box>
      <div style={{ minHeight: '100%' }}>
        {loading ? (
          <Box textAlign="center" py={2}>
            <CircularProgress />
          </Box>
        ) : (
          filterListByStatus(state.conversationList, CONVERSATION_STATUS.ACTIVE)
            .filter(({ moderatorId }) => moderatorId.id === user.id)
            .sort(
              sortByOldest
                ? sortByDate.ascending('createdAt')
                : sortByDate.descending('createdAt')
            )
            .map(conversation => (
              <ConversationListItem
                key={conversation.id}
                hasNewMessage={
                  hasNewMessages && hasNewMessages(conversation.id)
                }
                {...conversation}
              />
            ))
        )}
        <Box p={2} pb={0} mt={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Meeleesgesprekken
          </Typography>
        </Box>
        {loading ? (
          <Box textAlign="center" py={2}>
            <CircularProgress />
          </Box>
        ) : (
          filterListByStatus(state.conversationList, CONVERSATION_STATUS.ACTIVE)
            .filter(({ moderatorId }) => moderatorId.id !== user.id)
            .sort(
              sortByOldest
                ? sortByDate.ascending('createdAt')
                : sortByDate.descending('createdAt')
            )
            .map(conversation => (
              <ConversationListItem
                key={conversation.id}
                hasNewMessage={
                  hasNewMessages && hasNewMessages(conversation.id)
                }
                {...conversation}
              />
            ))
        )}
      </div>
      <Box
        borderTop={1}
        bottom={'0px'}
        padding={'5px'}
        display={'flex'}
        bgcolor={'white'}
        direction={'row'}
        position={'sticky'}
        borderColor={'grey.300'}
        justifyContent={'space-around'}
        style={{ zIndex: 1299 }}
      >
        <Button
          disabled={pageNumber < 1}
          variant="contained"
          onClick={getPrevPage}
        >
          Vorige 50
        </Button>
        <Button
          disabled={
            pageTokens[pageNumber] && pageTokens[pageNumber].next == null
          }
          variant="contained"
          onClick={getNextPage}
        >
          Volgende 50
        </Button>
      </Box>
    </div>
  );
};

ActiveConversationList.propTypes = {
  fetchConversations: func,
};

export default ActiveConversationList;
