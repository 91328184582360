import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { func } from 'prop-types';

const NewNote = ({ createNote }) => {
  const [content, setContent] = useState('');
  const [consult, setConsult] = useState(false);

  const handleCreatingNote = () => {
    createNote(content, consult);
    setConsult(false);
    setContent('');
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Notitie toevoegen
      </Typography>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        rows={8}
        value={content}
        onChange={e => setContent(e.target.value)}
        placeholder="Type hier uw notitie"
      />
      <Box mt={1}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={consult}
                  onChange={e => setConsult(e.target.checked)}
                  color="primary"
                />
              }
              label="Achterwacht geraadpleegd"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              onClick={handleCreatingNote}
              disabled={content === '' && !consult}
              disableElevation
            >
              Opslaan
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

NewNote.propTypes = {
  createNote: func,
};

export default NewNote;
