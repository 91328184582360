import React, { useContext, useEffect, useState } from 'react';
import UserComponent from '../components/User';
import { userContext } from '../contexts/userContext';
import ConfirmationPrompt from '../components/ConfirmationPrompt';
import stateContext from '../contexts/stateContext';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { updateConversation } from '../graphql/mutations';
import { clearState } from '../actions';
import { CONVERSATION_STATUS } from '../constants';

const reopenConversation = id =>
  API.graphql(
    graphqlOperation(updateConversation, {
      id,
      status: CONVERSATION_STATUS.OPEN,
    })
  );

const User = () => {
  const { user, setUser } = useContext(userContext);
  const { state, dispatch } = useContext(stateContext);
  const [activeConversations, setActiveConversations] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (state.conversationList.length) {
      setActiveConversations(
        state.conversationList
          .filter(({ status }) => status === CONVERSATION_STATUS.ACTIVE)
          .filter(({ moderatorId }) => moderatorId.id === user.id)
      );
    }
  }, [state.conversationList, user.id]);

  const logoutText = 'Weet je zeker dat je wilt afmelden';
  const closeConversationsAndLogoutText = `Je hebt nog ${
    activeConversations.length
  } ${
    activeConversations.length > 1 ? 'actieve gesprekken' : 'actief gesprek'
  }. Zodra je afmeldt, wordt je ontkoppeld als gespreksleider van dit gesprek en worden deze gesprekken teruggeplaatst. Weet je zeker dat je je wil afmelden?`;

  const handleLogout = async () => {
    if (activeConversations.length) {
      activeConversations.forEach(({ id }) => reopenConversation(id));
    }
    Auth.signOut().then(() => {
      dispatch(clearState(null));
      setUser(null);
    });
  };
  return (
    <>
      <UserComponent user={user} logout={() => setShowPrompt(true)} />
      <ConfirmationPrompt
        header="afmelden"
        content={
          activeConversations.length
            ? closeConversationsAndLogoutText
            : logoutText
        }
        confirmLabel="Afmelden"
        action={handleLogout}
        show={showPrompt}
        close={() => setShowPrompt(false)}
      />
    </>
  );
};

export default User;
