export const updateConversationAndCreateLog = /* GraphQL */ `
  mutation UpdateConversationAndCreateLog(
    $status: ConversationStatus!
    $moderatorId: String
    $closedType: ClosingType
    $conversationId: ID!
    $logStatus: String!
    $logConversationUserName: String
  ) {
    createConversationStatusLog(
      conversationId: $conversationId
      status: $logStatus
      name: $logConversationUserName
    ) {
      conversationId
      status
      name
      createdAt
    }
    updateConversation(
      id: $conversationId
      status: $status
      moderatorId: $moderatorId
      closedType: $closedType
    ) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;

export const createConversationStatusLog = /* GraphQL */ `
  mutation CreateConversationStatusLog(
    $conversationId: ID!
    $status: String!
    $name: String
  ) {
    createConversationStatusLog(
      conversationId: $conversationId
      status: $status
      name: $name
    ) {
      conversationId
      status
      name
      createdAt
    }
  }
`;
