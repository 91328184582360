import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { func, shape } from 'prop-types';
import { userType } from '../contexts/userContext';

const User = ({ user, logout }) => (
  <Box p={2}>
    <Typography color="textSecondary">Ingelogd als:</Typography>
    <Typography variant="subtitle1" gutterBottom noWrap>
      {user.name}
    </Typography>
    <Button
      variant="contained"
      color="primary"
      startIcon={<ExitToAppIcon />}
      onClick={logout}
      disableElevation
    >
      Afmelden
    </Button>
  </Box>
);

User.propTypes = {
  user: shape(userType),
  logout: func,
};

export default User;
