import copy from '../../copy';

const getCopy = key => {
  if (typeof key !== 'string') {
    console.error(`getCopy: key is invalid type: ${typeof key}`);
    return '';
  }
  const value = key.split('.').reduce((acc, key) => acc[key], copy);

  if (!value) {
    throw new Error(`Key ${key} not found in copy!`);
  }

  return value;
};

export default getCopy;
