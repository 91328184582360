import gql from 'graphql-tag';
import { userFragment, statusLogFragment } from '../../fragments';

export const searchConversationsQuery = gql`
query SearchConversations($searchQuery: SearchQuery) {
  searchConversations(searchQuery: $searchQuery) {
    id
    subject
    clientId
    createdAt
    client {
        ...UserFragment
      }
    conversationStatusLog {
      items {
        ...StatusLogFragment
      }
    }
  }
}${userFragment}, ${statusLogFragment}`;
