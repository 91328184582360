import gql from 'graphql-tag';
import { userFragment } from '../../fragments';

export const onCreateConversationSubscription = gql`
  subscription OnCreateConversation {
    onCreateConversation {
      id
      subject
      status
      clientId
      client {
        ...UserFragment
      }
      createdAt
    }
  }
  ${userFragment}
`;
