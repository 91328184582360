const handleSorting = (a, b, key, inverse) => {
  const _a = key ? a[key] : a;
  const _b = key ? b[key] : b;
  return new Date(inverse ? _a : _b) - new Date(inverse ? _b : _a);
};

const sortByDate = {
  descending: key => (a, b) => handleSorting(a, b, key),
  ascending: key => (a, b) => handleSorting(a, b, key, true),
};

export default sortByDate;
