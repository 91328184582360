import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { string } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import Close from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.modal,
    background: 'rgba(0,0,0,0.8)',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
}));

const ImageThumb = ({ image, senderName, createdAt }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleEscape = e => e.key === 'Escape' && setOpen(false);

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleEscape);
    }
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [open]);
  return (
    <>
      <Box position="relative" overflow="hidden">
        <Box position="absolute" right={0} color="common.white">
          <IconButton color="inherit" onClick={() => setOpen(true)}>
            <ZoomIn />
          </IconButton>
        </Box>
        <img src={image} alt="" className={classes.image} />
      </Box>
      <Backdrop
        open={open}
        onClose={() => setOpen(false)}
        className={classes.backdrop}
      >
        <Box
          color="common.white"
          position="absolute"
          top={0}
          width="100%"
          display="flex"
          p={1}
        >
          <Box width="100%">
            <Typography variant="body1">{senderName}</Typography>
            <Typography variant="subtitle2">
              {format(new Date(createdAt), 'dd MMM yyyy hh:mm')}
            </Typography>
          </Box>
          <IconButton
            href={image}
            download={`medidesk_image_${senderName}_${format(
              new Date(createdAt),
              'dd-MM-yyyy'
            )}`}
            color="inherit"
          >
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={() => setOpen(false)} color="inherit">
            <Close />
          </IconButton>
        </Box>
        <Box
          overflow="hidden"
          height="90%"
          width="90%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={image} alt="" className={classes.image} />
        </Box>
      </Backdrop>
    </>
  );
};

ImageThumb.propTypes = {
  image: string,
  senderName: string,
  createdAt: string,
};

export default ImageThumb;
