import { CONVERSATION_CLOSING_TYPE, LOG_TYPES } from './constants';

const copy = {
  active: 'actief',
  observe: 'meelezen',
  open: 'open',
  closed: 'gesloten',
  archived: 'gearchiveerd',
  reopened: 'teruggeplaatst',
  reopen: 'terugplaatsen',
  eventBy: {
    [LOG_TYPES.OPEN]: 'Gesprek is teruggeplaatst door ',
    [LOG_TYPES.ACTIVE]: 'Gesprek is gestart door ',
    [LOG_TYPES.CLOSED]: 'Gesprek is gesloten door ',
    [LOG_TYPES.ARCHIVED]: 'Gesprek is automatisch gearchiveerd',
    [LOG_TYPES.NOTE]: 'Notitie toegevoegd door ',
    [LOG_TYPES.CONSULT]: 'Achterwacht ingeschakeld door ',
    [LOG_TYPES.CONSULT_NOTE]: 'Achterwacht ingeschakeld met notitie door ',
    [LOG_TYPES.CLOSE_TYPE_UPDATE]: 'Type bijgewerkt door ',
  },
  eventOn: {
    [LOG_TYPES.ACTIVE]: 'Geopend op:',
    [LOG_TYPES.CLOSED]: 'Gesloten op:',
    [LOG_TYPES.OPEN]: 'Teruggeplaatst op:',
    [LOG_TYPES.ARCHIVED]: 'Gearchiveerd op:',
    [LOG_TYPES.NOTE]: 'Notitie toegevoegd op:',
    [LOG_TYPES.CONSULT]: 'Achterwacht ingeschakeld op:',
    [LOG_TYPES.CONSULT_NOTE]: 'Achterwacht ingeschakeld met notitie op:',
    [LOG_TYPES.CLOSE_TYPE_UPDATE]: 'Type bijgewerkt op:',
  },
  [CONVERSATION_CLOSING_TYPE.NO_CONSULT]: {
    title: 'Afhandeling door verpleegkundige',
    description: 'Simpele dossiers, geen inzet achterwacht-arts',
  },
  [CONVERSATION_CLOSING_TYPE.CONSULT_AFTERWARDS]: {
    title: 'Zelfzorgadvies door verpleegkundige',
    description:
      'De verpleegkundige geeft zelfzorgadvies en vraagt hiervoor achteraf schriftelijk akkoord van de achterwacht-arts',
  },
  [CONVERSATION_CLOSING_TYPE.CONSULT_CONTACT]: {
    title: 'Overleg verpleegkundige met achterwacht-arts',
    description:
      'De verpleegkundige houdt telefonisch ruggespraak met HA-achterwacht over het te geven advies aan de klant',
  },
  [CONVERSATION_CLOSING_TYPE.CONSULT_TAKE_OVER]: {
    title: 'Gesprek achterwacht-arts met klant',
    description:
      'Achterwacht-arts acht het noodzakelijk om zelf met de klant te spreken.',
  },
};

export default copy;
