const showNotification = title => {
  if (
    !document.hasFocus() &&
    Notification.permission === 'granted' &&
    !!title
  ) {
    new Notification(title);
  }
};

export default showNotification;
