/**
 * Adds an array of items to state.
 * @param state
 * @param items
 * @returns {*}
 */
const addList = (state, items) => {
  const newItems = items.filter(
    item => !state.find(({ id }) => id === item.id)
  );
  return state.concat(newItems);
};

/**
 * Updates an item on the state.
 * @param {Array} list
 * @param {Object} item
 * @returns {Array}
 */
const updateItem = (list, item) => {
  if (!Object.keys(item).length) {
    return list;
  }
  if (!Object.keys(item).includes('id')) {
    console.error('Item does not contain an Id');
    return list;
  }
  if (list.length && !list[0].id) {
    console.error('List items do not contain an Id');
    return list;
  }
  return list.map(listItem => (listItem.id === item.id ? item : listItem));
};

/**
 * Adds an item to an array when item is not yet present.
 * @param {Array} list
 * @param {Object} item
 * @returns {Array}
 */
const addItem = (list, item) => {
  if (!Object.keys(item).length) {
    return list;
  }
  if (!Object.keys(item).includes('id')) {
    console.error('Item does not contain an Id');
    return list;
  }
  if (list.length && !list[0].id) {
    console.error('List items do not contain an Id');
    return list;
  }
  return list.some(({ id }) => id === item.id) ? list : list.concat([item]);
};

const reducerHelpers = {
  addList,
  updateItem,
  addItem,
};

export default reducerHelpers;
