import React, { useContext, useEffect, useState } from 'react';
import stateContext from '../contexts/stateContext';
import { setConversation } from '../actions';
import { node } from 'prop-types';
import useSearchParams from '../hooks/useSearchParams';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { API, graphqlOperation } from 'aws-amplify';
import { getConversationById } from '../graphql/queries';

const Conversation = ({ children }) => {
  const { state, dispatch } = useContext(stateContext);
  const [loading, setLoading] = useState(false);
  const currentConversationId = useSearchParams('current');

  const fetchConversation = async () => {
    setLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(getConversationById, { id: currentConversationId })
      );
      const { data } = await response;

      if (data) {
        const { getConversationById } = data;
        dispatch(setConversation(getConversationById));
      }
    } catch (error) {
      console.error(
        `Failed to get conversation: ${currentConversationId} with:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentConversationId) {
      return fetchConversation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationId]);

  return state.conversation.id ? (
    <>{children}</>
  ) : (
    loading && (
      <Box mx="auto" mt={10}>
        <CircularProgress />
      </Box>
    )
  );
};

Conversation.propTypes = {
  children: node,
};

export default Conversation;
