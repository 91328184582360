import React, { useContext } from 'react';
import ConversationDrawer from '../components/ConversationDrawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import stateContext from '../contexts/stateContext';
import { bool, func } from 'prop-types';
import { getCopy, sortByDate } from '../utils';

const InformationDrawer = ({ show, close }) => {
  const { state } = useContext(stateContext);

  return (
    <>
      <ConversationDrawer
        show={show}
        close={close}
        title="Gespreksinformatie"
        {...state.conversation}
      >
        <Grid container>
          <Grid item xs={4}>
            <Typography color="textSecondary" gutterBottom>
              Binnen gekomen op:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>
              {format(
                new Date(state.conversation.createdAt),
                'dd-MM-yyyy HH:mm'
              )}
            </Typography>
          </Grid>
        </Grid>
        {state.conversation.conversationStatusLog.items
          .sort(sortByDate.ascending('createdAt'))
          .map((event, index) => (
            <Grid container key={index}>
              <Grid item xs={4}>
                <Typography color="textSecondary" gutterBottom>
                  {getCopy(`eventOn.${event.status}`)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography gutterBottom>
                  {format(new Date(event.createdAt), 'dd-MM-yyyy HH:mm')}
                  {event.name !== 'system' && ` door ${event.name}`}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </ConversationDrawer>
    </>
  );
};

InformationDrawer.propTypes = {
  show: bool,
  close: func,
};

export default InformationDrawer;
