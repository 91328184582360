import { format, isSameDay, subDays } from 'date-fns';

const today = new Date();
const yesterday = subDays(today, 1);

const parseDateString = date =>
  isSameDay(today, new Date(date))
    ? 'vandaag'
    : isSameDay(yesterday, new Date(date))
    ? 'gisteren'
    : format(new Date(date), 'dd-MM-yyyy');

export default parseDateString;
