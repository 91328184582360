import React, { createContext, useCallback, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getUser } from '../graphql/queries';

const { REACT_APP_AWS_USER_POOLS_ID } = process.env;

export const userContext = createContext({
  user: {},
  setUser: () => {},
});

const useAuthentication = () => {
  const [user, setUser] = useState(null);

  const getUserData = id =>
    API.graphql(
      graphqlOperation(getUser, {
        id,
      })
    );

  const handleSetUser = useCallback(async data => {
    if (!data) {
      setUser(null);
    } else {
      const id = `${REACT_APP_AWS_USER_POOLS_ID}_${data.attributes.sub}`;
      await getUserData(id)
        .then(({ data: { getUser } }) => setUser(getUser))
        .catch(err => console.error('Failed to get user data:', err));
    }
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(handleSetUser)
      .catch(err => console.error('Authentication failed:', err));
  }, [handleSetUser]);

  return { user, setUser: handleSetUser };
};

// eslint-disable-next-line react/display-name
export const withAuthentication = Component => props => {
  const authentication = useAuthentication();
  return (
    <userContext.Provider value={authentication}>
      <Component {...props} />
    </userContext.Provider>
  );
};

export const userType = {
  username: string,
  id: string,
};
