import { useCallback, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createConversationStatusLog } from '../../graphql/mutations';
import { LOG_TYPES } from '../../constants';
import stateContext from '../../contexts/stateContext';
import { updateConversation, updateConversationList } from '../../actions';
import { userContext } from '../../contexts/userContext';

const addLogItem = (conversation, item) => ({
  ...conversation,
  conversationStatusLog: {
    ...conversation.conversationStatusLog,
    items: conversation.conversationStatusLog.items.concat(item),
  },
});

const useConversationLog = conversation => {
  const { dispatch } = useContext(stateContext);
  const { user } = useContext(userContext);

  const createLog = useCallback(
    status => {
      if (Object.values(LOG_TYPES).indexOf(status) === -1) {
        console.error(`Invalid status type: ${status}`);
        return null;
      }

      API.graphql(
        graphqlOperation(createConversationStatusLog, {
          conversationId: conversation.id,
          status,
          name: user.name,
        })
      )
        .then(({ data: { createConversationStatusLog: response } }) => {
          const newConversationData = addLogItem(conversation, response);
          dispatch(updateConversationList(newConversationData));
          dispatch(updateConversation(newConversationData));
        })
        .catch(err => console.error('Failed creating status log:', err));
    },
    [conversation, dispatch, user.name]
  );

  return {
    createLog,
  };
};

export default useConversationLog;
