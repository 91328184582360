import { format } from 'date-fns';
import sortByDate from '../sortByDate';

const formatDate = date => format(new Date(date), 'yyyy-MM-dd');

const clusterMessagesByDay = messages =>
  messages
    .reduce(
      (acc, message) =>
        acc.indexOf(formatDate(message.createdAt)) === -1
          ? acc.concat([formatDate(message.createdAt)])
          : acc,
      []
    )
    .map(date =>
      messages
        .filter(message => formatDate(message.createdAt) === date)
        .sort(sortByDate.ascending('createdAt'))
    )
    .sort((a, b) => sortByDate.ascending('createdAt')(a[0], b[0]));

export default clusterMessagesByDay;
