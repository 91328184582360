import { useLocation } from 'react-router';

const useSearchParams = query => {
  const { search } = useLocation();

  if (search) {
    const key = search.slice(1, search.indexOf('='));
    const value = search.replace(`?${query}=`, '');
    return key === query ? value : null;
  }
  return null;
};

export default useSearchParams;
