import { isAfter } from 'date-fns';
import { sortByDate } from '../index';
import { LOG_TYPES } from '../../constants';

const conversationHasBeenReopend = statusLogs => {
  const getLogItem = status =>
    statusLogs
      .sort(sortByDate.descending('createdAt'))
      .find(logItem => logItem.status === status);

  const lastTimeActive = getLogItem(LOG_TYPES.ACTIVE);
  const lastTimeOpen = getLogItem(LOG_TYPES.OPEN);

  return (
    lastTimeActive &&
    lastTimeOpen &&
    isAfter(
      new Date(lastTimeOpen.createdAt),
      new Date(lastTimeActive.createdAt)
    )
  );
};

export default conversationHasBeenReopend;
