import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { bool, func, shape } from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { CONVERSATION_CLOSING_TYPE } from '../constants';
import { getCopy } from '../utils';

const CloseConversationPrompt = ({ show, close, conversation, action }) => {
  const [type, setType] = useState(conversation.closedType);

  const optionTypes = Object.keys(CONVERSATION_CLOSING_TYPE);
  const options = optionTypes.map(type => ({
    ...getCopy(type),
    value: type,
  }));

  const handleSetType = e => setType(e.target.value);

  const handleClose = value => {
    if (value) {
      action(type);
    }
    close();
    setType(null);
  };

  return (
    <Dialog open={show} maxWidth="xs">
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">GESPREK SLUITEN</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Om dit gesprek te sluiten vragen we je een type toe te kennen
        </Typography>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={type}
          onChange={handleSetType}
        >
          {options.map(({ title, description, value }) => (
            <Box key={value} my={2}>
              <FormControlLabel
                value={value}
                control={<Radio color="primary" />}
                label={
                  <>
                    <Typography variant="body1">{title}</Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      component="div"
                    >
                      {description}
                    </Typography>
                  </>
                }
              />
            </Box>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => handleClose(false)}
          disableElevation
        >
          annuleren
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClose(true)}
          disableElevation
          disabled={!type}
        >
          {conversation.closedType ? 'bijwerken' : 'afsluiten'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CloseConversationPrompt.propTypes = {
  show: bool,
  close: func,
  conversation: shape({}),
  action: func,
};

export default CloseConversationPrompt;
