import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { bool } from 'prop-types';
import FromToForm from '../../containers/FromToForm';
import { startOfMonth } from 'date-fns';
import { API, graphqlOperation } from 'aws-amplify';
import { getNewUserCount } from '../../graphql/queries';

const Registrations = ({ isVisible = true }) => {
  const defaultStart = startOfMonth(new Date());
  const defaultEnd = new Date();
  const [loading, setLoading] = useState(false);
  const [registrations, setRegistrations] = useState();

  const handleClick = useCallback(params => {
    setLoading(true);
    return API.graphql(graphqlOperation(getNewUserCount, params))
      .then(({ data: { getNewUserCount } }) =>
        setRegistrations(getNewUserCount)
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box display={isVisible ? 'block' : 'none'}>
      <Typography variant="h1">Registraties</Typography>
      <Typography variant="body1">
        Toon alle app registraties voor periode:
      </Typography>
      <FromToForm
        defaultStart={defaultStart}
        defaultEnd={defaultEnd}
        handleClick={handleClick}
        buttonText="Toon resultaat"
        busy={loading}
      />
      {registrations > -1 && (
        <Box mt={2}>
          <Typography variant="body1">
            Get aantal app registraties voor deze periode is:{' '}
            <Box display="inline" bgcolor="grey.300" padding={1}>
              {registrations}
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Registrations.propTypes = {
  isVisible: bool,
};

export default Registrations;
