import React, { useCallback, useState } from 'react';
import { Box, Drawer, Typography } from '@material-ui/core';
import { func, string } from 'prop-types';
import SimpleTabs from '../../components/SimpleTabs';
import Statistics from './Statistics';
import Registrations from './Registrations';

const tabs = [
  { id: 'stats', name: 'Statistieken' },
  { id: 'registrations', name: 'Registraties' },
];

const Settings = ({ setSettingsState, settingsState }) => {
  const onClose = useCallback(() => {
    setSettingsState(null);
  }, [setSettingsState]);

  const [value, setValue] = useState(tabs[0].id);

  const onChangeTabs = (e, id) => {
    console.log('CHANGING TABS', id);
    setValue(id);
  };

  return (
    <Drawer
      anchor="right"
      open={settingsState === 'instellingen'}
      onClose={onClose}
      variant="temporary"
    >
      <Box
        bgcolor="grey.100"
        height="100vh"
        width="83.33vw"
        display="flex"
        flexDirection="column"
      >
        <Box p={4}>
          <Typography variant="h1">Instellingen</Typography>
        </Box>

        <SimpleTabs tabs={tabs} onChange={onChangeTabs} value={value} />

        <Box p={4}>
          <Registrations isVisible={value === 'registrations'} />
          <Statistics isVisible={value === 'stats'} />
        </Box>
      </Box>
    </Drawer>
  );
};

Settings.propTypes = {
  settingsState: string,
  setSettingsState: func,
};

export default Settings;
