/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConversationById = /* GraphQL */ `
  query GetConversationById($id: ID!) {
    getConversationById(id: $id) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
          status {
            read
            received
          }
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const getConversationsByStatus = /* GraphQL */ `
  query GetConversationsByStatus(
    $status: String!
    $limit: Int
    $nextToken: String
  ) {
    getConversationsByStatus(
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        status
        messages {
          items {
            createdAt
            conversationId
            content
            sender
            senderUser {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            receiver
            image
          }
          nextToken
        }
        moderatorId {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        moderatorName {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientId
        client {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientName
        notes {
          items {
            id
            conversationId
            content
            consultedSecondLine
            moderator {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        conversationStatusLog {
          items {
            conversationId
            status
            name
            createdAt
          }
          nextToken
        }
        createdAt
        updatedAt
        deleted
        firstActiveAt
        closedType
      }
      nextToken
    }
  }
`;
export const getConversationsByClient = /* GraphQL */ `
  query GetConversationsByClient(
    $clientId: String
    $limit: Int
    $nextToken: String
  ) {
    getConversationsByClient(
      clientId: $clientId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        status
        messages {
          items {
            createdAt
            conversationId
            content
            sender
            senderUser {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            receiver
            image
          }
          nextToken
        }
        moderatorId {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        moderatorName {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientId
        client {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientName
        notes {
          items {
            id
            conversationId
            content
            consultedSecondLine
            moderator {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        conversationStatusLog {
          items {
            conversationId
            status
            name
            createdAt
          }
          nextToken
        }
        createdAt
        updatedAt
        deleted
        firstActiveAt
        closedType
      }
      nextToken
    }
  }
`;
export const getConversationsByModerator = /* GraphQL */ `
  query GetConversationsByModerator(
    $moderatorId: String!
    $limit: Int
    $nextToken: String
  ) {
    getConversationsByModerator(
      moderatorId: $moderatorId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        status
        messages {
          items {
            createdAt
            conversationId
            content
            sender
            senderUser {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            receiver
            image
          }
          nextToken
        }
        moderatorId {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        moderatorName {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientId
        client {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        clientName
        notes {
          items {
            id
            conversationId
            content
            consultedSecondLine
            moderator {
              id
              cognitoId
              userpool
              name
              push_subscription
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        conversationStatusLog {
          items {
            conversationId
            status
            name
            createdAt
          }
          nextToken
        }
        createdAt
        updatedAt
        deleted
        firstActiveAt
        closedType
      }
      nextToken
    }
  }
`;
export const getConversationStatusLogs = /* GraphQL */ `
  query GetConversationStatusLogs($conversationId: ID!) {
    getConversationStatusLogs(conversationId: $conversationId) {
      conversationId
      status
      name
      createdAt
    }
  }
`;
export const listModerators = /* GraphQL */ `
  query ListModerators {
    listModerators {
      id
      cognitoId
      name
    }
  }
`;
export const searchConversations = /* GraphQL */ `
  query SearchConversations($searchQuery: SearchQuery) {
    searchConversations(searchQuery: $searchQuery) {
      id
      subject
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes($conversationId: ID!, $limit: Int, $nextToken: String) {
    listNotes(
      conversationId: $conversationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationId
        content
        consultedSecondLine
        moderator {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($conversationId: ID!, $limit: Int, $nextToken: String) {
    listMessages(
      conversationId: $conversationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        conversationId
        content
        sender
        senderUser {
          id
          cognitoId
          userpool
          name
          push_subscription
          createdAt
          updatedAt
        }
        receiver
        image
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const generateReport = /* GraphQL */ `
  query GenerateReport($startDate: AWSDate!, $endDate: AWSDate!) {
    generateReport(startDate: $startDate, endDate: $endDate)
  }
`;
export const getNewUserCount = /* GraphQL */ `
  query GetNewUserCount($startDate: AWSDate!, $endDate: AWSDate!) {
    getNewUserCount(startDate: $startDate, endDate: $endDate)
  }
`;
