import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { getClosingDate, sortByDate } from '../../utils';
import { CONVERSATION_LIMIT } from '../../constants';
import { CONVERSATION_STATUS } from '../../constants';
import stateContext from '../../contexts/stateContext';
import { func } from 'prop-types';
import { listModerators } from '../../graphql/queries';
import ConversationListItem from '../../components/ConversationListItem';
import SearchConversations from '../../components/SearchConversations';
import { API, graphqlOperation } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import getLatestStatus from '../../utils/getLatestStatus';
import { closedAndArchivedConversationsQuery } from './closedAndArchivedConversationsQuery';
import { searchConversationsQuery } from './searchConversationsQuery';
import { Button, CircularProgress, Box, Divider } from '@material-ui/core';

const ArchivedConversationList = ({ fetchConversations }) => {
  let [pageNumber, setPageNumber] = useState(0);
  const { state } = useContext(stateContext);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moderators, setModerators] = useState([]);
  const [pageTokens, setPageTokens] = useState({});
  const [enablePagination, setEnablePagination] = useState(true);
  const [searchContainerHeight, setSearchContainerHeight] = useState(0);
  const searchContainerRef = useRef(null);

  const getPrevPage = () => {
    Promise.all([
      fetchConversations(
        closedAndArchivedConversationsQuery,
        CONVERSATION_STATUS.ARCHIVED,
        CONVERSATION_LIMIT,
        pageTokens[pageNumber].prev
      ),
    ]);
    setPageNumber(--pageNumber);
    setLoading(true);
  };

  const getNextPage = () => {
    Promise.all([
      fetchConversations(
        closedAndArchivedConversationsQuery,
        CONVERSATION_STATUS.ARCHIVED,
        CONVERSATION_LIMIT,
        pageTokens[pageNumber].next
      ),
    ]);
    setPageNumber(++pageNumber);
    setLoading(true);
  };

  const getSearchConversations = useCallback(params => {
    setEnablePagination(false);
    setLoading(true);
    setShowResults(true);
    return API.graphql(graphqlOperation(searchConversationsQuery, params))
      .then(({ data: { searchConversations } }) => {
        setSearchResults(searchConversations);
        setPageNumber(0);
      })
      .finally(() => setLoading(false));
  }, []);

  const resetResults = () => {
    setSearchResults([]);
    setEnablePagination(true);
    setPageNumber(0);
    setShowResults(false);
  };

  /**
   * Get closed and archived conversations
   */
  useEffect(() => {
    Promise.all([
      fetchConversations(
        closedAndArchivedConversationsQuery,
        CONVERSATION_STATUS.ARCHIVED,
        CONVERSATION_LIMIT
      ),
    ]);
  }, [fetchConversations]);

  useEffect(() => {
    setLoading(false);
    setPageTokens({
      ...pageTokens,
      [pageNumber]: {
        prev: pageTokens[pageNumber - 2]
          ? pageTokens[pageNumber - 2].next
          : null,
        next: state.nextToken,
      },
    });
  }, [state.conversationList]);

  /**
   * Get list of moderators
   */
  useEffect(() => {
    API.graphql(graphqlOperation(listModerators)).then(
      ({ data: { listModerators } }) => setModerators(listModerators)
    );
  }, []);

  useEffect(() => {
    if (searchContainerRef.current !== null && searchContainerHeight === 0) {
      setSearchContainerHeight(searchContainerRef.current.offsetHeight);
    }
  }, [searchContainerRef, searchContainerHeight]);

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <SearchConversations
        searchConversations={getSearchConversations}
        resetSearchResults={resetResults}
        moderators={moderators}
        ref={searchContainerRef}
      />
      <Divider />
      <div
        style={{ minHeight: `calc(100% - ${searchContainerHeight}px - 55px)` }}
      >
        {showResults ? (
          loading ? (
            <Box textAlign="center" py={2}>
              <CircularProgress />
            </Box>
          ) : searchResults.length > 0 ? (
            searchResults
              .map(conversation => ({
                ...conversation,
                closedAt: getClosingDate(
                  conversation.conversationStatusLog.items
                ),
                status: getLatestStatus(
                  conversation.conversationStatusLog.items
                ),
              }))
              .sort(sortByDate.descending('closedAt'))
              .map(conversation => (
                <ConversationListItem key={conversation.id} {...conversation} />
              ))
          ) : (
            <Typography
              align="center"
              variant="overline"
              component="div"
              color="textSecondary"
            >
              Geen gesprekken gevonden
            </Typography>
          )
        ) : loading ? (
          <Box textAlign="center" py={2}>
            <CircularProgress />
          </Box>
        ) : (
          state.conversationList
            .map(conversation => ({
              ...conversation,
              closedAt: getClosingDate(
                conversation.conversationStatusLog.items
              ),
            }))
            .sort(sortByDate.descending('closedAt'))
            .map((conversation, index) => (
              <ConversationListItem
                id={'conversation-item-' + index}
                key={conversation.id}
                {...conversation}
              />
            ))
        )}
      </div>
      {enablePagination && (
        <Box
          borderTop={1}
          bottom={'0px'}
          padding={'5px'}
          display={'flex'}
          bgcolor={'white'}
          direction={'row'}
          position={'sticky'}
          borderColor={'grey.300'}
          justifyContent={'space-around'}
        >
          <Button
            disabled={pageNumber < 1}
            variant="contained"
            onClick={getPrevPage}
          >
            Vorige 50
          </Button>
          <Button
            disabled={
              pageTokens[pageNumber] && pageTokens[pageNumber].next == null
            }
            variant="contained"
            onClick={getNextPage}
          >
            Volgende 50
          </Button>
        </Box>
      )}
    </div>
  );
};

ArchivedConversationList.propTypes = {
  fetchConversations: func,
};

export default ArchivedConversationList;
