import React, { useContext, useEffect } from 'react';
import ConversationDrawer from '../components/ConversationDrawer';
import Notes from '../components/Notes';
import NewNote from '../components/NewNote';
import Box from '@material-ui/core/Box';
import stateContext from '../contexts/stateContext';
import { bool, func } from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { createNote as createNoteQuery } from '../graphql/mutations';
import { onCreateNote } from '../graphql/subscriptions';
import { addNote } from '../actions';
import useConversationLog from '../hooks/useConversationLog';
import { LOG_TYPES } from '../constants';

const NotesDrawer = ({ show, close }) => {
  const { state, dispatch } = useContext(stateContext);
  const { createLog } = useConversationLog(state.conversation);

  const createNote = (content, consultedSecondLine) => {
    API.graphql(
      graphqlOperation(createNoteQuery, {
        input: {
          content,
          consultedSecondLine,
          conversationId: state.conversation.id,
        },
      })
    );
    if (consultedSecondLine) {
      createLog(content ? LOG_TYPES.CONSULT_NOTE : LOG_TYPES.CONSULT);
    } else {
      createLog(LOG_TYPES.NOTE);
    }
  };

  useEffect(() => {
    const notesSubscription = API.graphql(
      graphqlOperation(onCreateNote)
    ).subscribe({
      next: ({ value }) => dispatch(addNote(value.data.onCreateNote)),
    });

    return () => {
      notesSubscription.unsubscribe();
    };
  }, [dispatch]);

  return (
    <ConversationDrawer
      show={show}
      close={close}
      title="Notities"
      {...state.conversation}
    >
      <Box mb={4}>
        <NewNote createNote={createNote} />
      </Box>
      <Notes notes={state.conversation.notes.items} />
    </ConversationDrawer>
  );
};

NotesDrawer.propTypes = {
  show: bool,
  close: func,
};

export default NotesDrawer;
