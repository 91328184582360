import React, { useContext, useEffect, useState } from 'react';
import ConversationTabsComponent from '../components/ConversationTabs';
import stateContext from '../contexts/stateContext';
import { deselectConversation } from '../actions';
import { useHistory, useRouteMatch } from 'react-router';
import { userContext } from '../contexts/userContext';
import useSearchParams from '../hooks/useSearchParams';

const ConversationTabs = () => {
  const { state, dispatch } = useContext(stateContext);
  const history = useHistory();
  const { path } = useRouteMatch();
  const currentConversationId = useSearchParams('current');
  const [value, setValue] = useState(currentConversationId);
  const { user } = useContext(userContext);

  const onChange = (e, id) => {
    setValue(id);
    history.push(`${path}?current=${id}`);
  };

  const onClose = (e, conversationId) => {
    e.stopPropagation();
    dispatch(deselectConversation(conversationId));

    if (conversationId === currentConversationId) {
      const remainingSelectedConversations = state.selectedConversations.filter(
        ({ id }) => id !== conversationId
      );

      if (remainingSelectedConversations.length) {
        const lastIndex = remainingSelectedConversations.length - 1;
        history.push(
          `${path}?current=${remainingSelectedConversations[lastIndex].id}`
        );
      } else {
        history.push(path);
      }
    }
  };

  useEffect(() => {
    if (currentConversationId) {
      setValue(currentConversationId);
    }
  }, [currentConversationId]);

  return (
    <ConversationTabsComponent
      selectedConversations={state.selectedConversations}
      userId={user.id}
      onClose={onClose}
      onChange={onChange}
      value={value}
      newMessages={state.newMessageNotifications}
    />
  );
};

export default ConversationTabs;
