import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = {
  palette: {
    primary: {
      main: '#FF6600',
      light: '#fdf0e6',
      lighter: '#f5cfba',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00A0DF',
      contrastText: '#ffffff',
    },
    text: {
      secondary: '#7b7b7b',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Blender Pro, sans-serif',
      fontSize: '20px',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: 'Arial Narrow',
    },
    subtitle1: {
      fontFamily: 'Blender Pro, sans-serif',
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontFamily: 'Arial Narrow',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    button: {
      fontFamily: 'BlenderPro, sans-serif',
      fontWeight: 'bold',
    },
    overline: {
      fontFamily: 'Arial Narrow, sans-serif',
      fontSize: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },
};

const overrides = {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
};

// eslint-disable-next-line react/display-name
export const withTheme = Component => props => {
  const muiTheme = createTheme({ ...theme, ...overrides });
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>
  );
};
