import { shape, string } from 'prop-types';

export default shape({
  id: string,
  cognitoId: string,
  userpool: string,
  name: string,
  createdAt: string,
  updatedAt: string,
});
