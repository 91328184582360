import React, { useCallback, useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { setConversationList } from '../../actions';
import { setNextToken } from '../../actions';
import stateContext from '../../contexts/stateContext';
import Box from '@material-ui/core/Box';
import { API, graphqlOperation } from 'aws-amplify';
import { CONVERSATION_STATUS } from '../../constants';
import OpenConversationList from './OpenConversationList';
import ActiveConversationList from './ActiveConversationList';
import ClosedConversationList from './ClosedConversationList';
import ArchivedConversationList from './ArchivedConversationList';

const ConversationList = () => {
  const { dispatch } = useContext(stateContext);
  const { path } = useRouteMatch();
  const conversationType = path.replace('/', '');

  const fetchConversationsByStatus = useCallback(
    (query, status, limit, nextToken) =>
      API.graphql(
        graphqlOperation(query, {
          status,
          limit,
          nextToken,
        })
      )
        .then(
          ({
            data: {
              getConversationsByStatus: { items, nextToken },
            },
          }) => {
            dispatch(setNextToken(nextToken));
            dispatch(setConversationList(items));
          }
        )
        .catch(err =>
          console.error(
            `Failed to get conversations with status: ${status}.`,
            err
          )
        ),
    [dispatch]
  );

  return (
    <Box style={{ overflowY: 'scroll', height: '100vh' }}>
      {conversationType === CONVERSATION_STATUS.OPEN && (
        <OpenConversationList fetchConversations={fetchConversationsByStatus} />
      )}
      {conversationType === CONVERSATION_STATUS.ACTIVE && (
        <ActiveConversationList
          fetchConversations={fetchConversationsByStatus}
        />
      )}
      {conversationType === CONVERSATION_STATUS.CLOSED && (
        <ClosedConversationList
          fetchConversations={fetchConversationsByStatus}
        />
      )}
      {conversationType === CONVERSATION_STATUS.ARCHIVED && (
        <ArchivedConversationList
          fetchConversations={fetchConversationsByStatus}
        />
      )}
    </Box>
  );
};

export default ConversationList;
