import {
  SET_CONVERSATION,
  SET_CONVERSATION_LIST,
  SET_NEXT_TOKEN,
  ADD_TO_CONVERSATION_LIST,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_LIST,
  DESELECT_CONVERSATION,
  RESET_NEW_CONVERSATION_COUNT,
  ADD_NOTE,
  ADD_MESSAGE,
  UPDATE_MESSAGE_STATUS,
  CLEAR_STATE,
  ADD_MESSAGE_NOTIFICATION,
} from './actions';
import { reducerHelpers } from './utils';

const { updateItem, addItem } = reducerHelpers;

export const initialState = {
  conversationList: [],
  selectedConversations: [],
  conversation: {
    messages: {
      items: [],
    },
    notes: {
      items: [],
    },
  },
  newConversationCount: 0,
  newMessageNotifications: [],
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: payload,
      };
    case SET_NEXT_TOKEN:
      return {
        ...state,
        nextToken: payload,
      };
    case ADD_TO_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: addItem(state.conversationList, payload),
        newConversationCount: state.newConversationCount + 1,
      };
    case UPDATE_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: updateItem(state.conversationList, payload),
      };
    case SET_CONVERSATION:
      return {
        ...state,
        conversation: payload,
        selectedConversations: reducerHelpers.addItem(
          state.selectedConversations,
          payload
        ),
        newMessageNotifications: state.newMessageNotifications.filter(
          id => id !== payload.id
        ),
      };
    case UPDATE_CONVERSATION:
      return {
        ...state,
        conversation:
          payload.id === state.conversation.id ? payload : state.conversation,
        conversationList: updateItem(state.conversationList, payload),
        selectedConversations: updateItem(state.selectedConversations, payload),
      };
    case DESELECT_CONVERSATION:
      return {
        ...state,
        conversation:
          payload === state.conversation.id ? {} : state.conversation,
        selectedConversations: state.selectedConversations.filter(
          ({ id }) => id !== payload
        ),
      };
    case RESET_NEW_CONVERSATION_COUNT:
      return {
        ...state,
        newConversationCount: 0,
      };
    case ADD_NOTE:
      return state.conversation.id === payload.conversationId
        ? {
            ...state,
            conversation: {
              ...state.conversation,
              notes: {
                ...state.conversation.notes,
                items: state.conversation.notes.items.concat([payload]),
              },
            },
          }
        : state;
    case ADD_MESSAGE:
      return state.conversation.id === payload.conversationId
        ? {
            ...state,
            conversation: {
              ...state.conversation,
              messages: {
                ...state.conversation.messages,
                items: state.conversation.messages.items.concat([payload]),
              },
            },
          }
        : state;
    case UPDATE_MESSAGE_STATUS:
      return state.conversation.id === payload.conversationId
        ? {
            ...state,
            conversation: {
              ...state.conversation,
              messages: {
                ...state.conversation.messages,
                items: state.conversation.messages.items.concat([payload]),
              },
            },
          }
        : state;
    case ADD_MESSAGE_NOTIFICATION:
      return {
        ...state,
        newMessageNotifications:
          state.newMessageNotifications.indexOf(payload) === -1
            ? state.newMessageNotifications.concat([payload])
            : state.newMessageNotifications,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      console.error(`No action found for ${type}.`);
      return state;
  }
};

export default reducer;
