import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

const useStyles = makeStyles(theme => ({
  item: {
    color: 'rgba(0,0,0,0.6)',
    '&.Mui-selected': {
      background: theme.palette.primary.light,
    },
  },
}));

const NavList = ({
  routes,
  newConversationCount,
  count,
  pathname,
  onClick,
  fiftyPlusChats,
}) => {
  const classes = useStyles();

  return (
    <List>
      {routes.map(({ icon, path, label }) => {
        const Icon = icon;
        const isActive = pathname === `/${path}`;
        return (
          <ListItem
            className={classes.item}
            key={path}
            button
            onClick={() => onClick(path)}
            selected={isActive}
          >
            <ListItemIcon>
              <Badge
                badgeContent={newConversationCount}
                color="primary"
                invisible={path !== 'open' || newConversationCount === 0}
              >
                <Icon color={isActive ? 'primary' : 'inherit'} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color={isActive ? 'primary' : 'inherit'}
                >
                  {label}
                </Typography>
              }
            />
            {(path == 'open' || path == 'active') && (
              <Typography
                variant="subtitle1"
                color={isActive ? 'primary' : 'inherit'}
              >
                {count[path]}
                {fiftyPlusChats[path] ? '+' : ''}
              </Typography>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

NavList.propTypes = {
  routes: arrayOf(
    shape({
      Icon: shape({}),
      path: string,
      label: string,
    })
  ),
  newConversationCount: number,

  count: shape({
    open: number,
    active: number,
  }),
  pathname: string,
  onClick: func,
  fiftyPlusChats: shape({
    open: bool,
    active: bool,
  }),
};

export default NavList;
