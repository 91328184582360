import React from 'react';
import { any, bool, oneOf, string } from 'prop-types';
import Box from '@material-ui/core/Box';
import { withTheme } from '@material-ui/core/styles';
import ImageThumb from './ImageThumb';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { UserType } from '../types';

const Message = ({
  theme,
  content,
  incoming,
  hasEarmark,
  image,
  senderName,
  createdAt,
  type,
  subject,
  senderUser,
}) => {
  const backgroundColor = incoming
    ? theme.palette.common.white
    : theme.palette.grey[200];
  return (
    <>
      <Box width="100%" display="flex">
        <Box
          maxWidth="60%"
          mt={1}
          mb={1}
          padding={2}
          whiteSpace="pre-line"
          position="relative"
          mr={incoming ? 'auto' : 0}
          ml={incoming ? 0 : 'auto'}
          bgcolor={backgroundColor}
        >
          {hasEarmark && (
            <Box
              position="absolute"
              top={0}
              width={0}
              height={0}
              borderLeft="8px solid transparent"
              borderRight="8px solid transparent"
              borderTop={`12px solid ${backgroundColor}`}
              left={incoming ? -8 : ''}
              right={incoming ? '' : -8}
            />
          )}
          {image && (
            <ImageThumb
              image={image}
              senderName={senderName}
              createdAt={createdAt}
            />
          )}
          {type === 'first' ? (
            <>
              <Typography variant="subtitle2">Onderwerp: {subject}</Typography>
              <Typography variant="body1">Klacht: {content}</Typography>
            </>
          ) : (
            <Box position="relative" pr={4}>
              {hasEarmark && !incoming && (
                <Typography variant="subtitle1">{senderUser.name}</Typography>
              )}
              {content}
              <Box position="absolute" bottom={-12} right={-8} color="grey.500">
                <Typography variant="subtitle2" color="inherit">
                  {format(new Date(createdAt), 'HH:mm')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

Message.propTypes = {
  content: string,
  incoming: bool,
  theme: any,
  hasEarmark: bool,
  image: string,
  senderName: string,
  createdAt: string,
  type: oneOf(['first', 'default']),
  subject: string,
  senderUser: UserType,
};

export default withTheme(Message);
