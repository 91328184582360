/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConversation = /* GraphQL */ `
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $id: ID!
    $status: ConversationStatus!
    $moderatorId: String
    $closedType: ClosingType
  ) {
    updateConversation(
      id: $id
      status: $status
      moderatorId: $moderatorId
      closedType: $closedType
    ) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const notifyUpdateConversation = /* GraphQL */ `
  mutation NotifyUpdateConversation($id: ID!) {
    notifyUpdateConversation(id: $id) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation($id: ID!) {
    deleteConversation(id: $id) {
      id
      subject
      status
      messages {
        items {
          createdAt
          conversationId
          content
          sender
          senderUser {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          receiver
          image
        }
        nextToken
      }
      moderatorId {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      moderatorName {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientId
      client {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      clientName
      notes {
        items {
          id
          conversationId
          content
          consultedSecondLine
          moderator {
            id
            cognitoId
            userpool
            name
            push_subscription
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      conversationStatusLog {
        items {
          conversationId
          status
          name
          createdAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deleted
      firstActiveAt
      closedType
    }
  }
`;
export const createConversationStatusLog = /* GraphQL */ `
  mutation CreateConversationStatusLog(
    $conversationId: ID!
    $status: String!
    $name: String
  ) {
    createConversationStatusLog(
      conversationId: $conversationId
      status: $status
      name: $name
    ) {
      conversationId
      status
      name
      createdAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      conversationId
      content
      consultedSecondLine
      moderator {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      conversationId
      content
      consultedSecondLine
      moderator {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      createdAt
      conversationId
      content
      sender
      senderUser {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      receiver
      image
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      createdAt
      conversationId
      content
      sender
      senderUser {
        id
        cognitoId
        userpool
        name
        push_subscription
        createdAt
        updatedAt
      }
      receiver
      image
    }
  }
`;

export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $conversationId: ID!
    $createdAt: AWSDateTime!
    $status: MessageStatusInput!
  ) {
    updateMessage(
      conversationId: $conversationId
      createdAt: $createdAt
      status: $status
    ) {
      createdAt
      conversationId
      content
      sender
      receiver
      image
      senderUser {
        id
        cognitoId
        name
        createdAt
        updatedAt
      }
      status {
        read
        received
      }
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      cognitoId
      userpool
      name
      push_subscription
      createdAt
      updatedAt
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage {
    createImage {
      upload
      download
      imageKey
    }
  }
`;
export const registerDevice = /* GraphQL */ `
  mutation RegisterDevice($provider: PushProvider!, $token: String!) {
    registerDevice(provider: $provider, token: $token)
  }
`;
